import styled from 'styled-components';
import { Text, AspectRatio } from '@strikelabs/luna';

export const TypeCard = styled.div`
  background: #fff;
  border-radius: 4px;
  position: relative;
`;

export const SemiCircle = styled.img`
  width: 140px;
  position: absolute;
  bottom: -1px;
  left: -2px;

  ${({ theme }) => theme.media.smallTablet} {
    width: 100px;
  }

  ${({ theme }) => theme.media.desktop} {
    width: 120px;
  }

  ${({ theme }) => theme.media.giant} {
    width: 140px;
  }
`;

export const AuthorText = styled(Text)`
  position: absolute;
  bottom: ${({ theme }) => theme.spacing.sm};
  left: ${({ theme }) => theme.spacing.sm};
`;

export const CardImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const RelativeAspectRatio = styled(AspectRatio)`
  position: relative;
`;
