import React from 'react';
import { Text } from '@strikelabs/luna';

import { Wrapper, Container } from '../style';
import MortgageTypeCard from './MortgageTypeCard';
import icoFtb from 'images/pages/mortgages/ico_ftb.png';
import icoInterestRate from 'images/pages/mortgages/ico_interest_rate.png';
import icoMortgage from 'images/pages/mortgages/ico_remortgage.png';
import typeFtb from 'images/pages/mortgages/type_ftb.jpg';
import typeRemortgage from 'images/pages/mortgages/type_remortgage.jpg';
import typeMovingHome from 'images/pages/mortgages/type_moving_home.jpg';
import { MortgageTypesCardContainer, ButtonContainer } from './style';
import { GoToPBMortgages } from '../../shared/GoToPBMortgages';

const mortgageTypeCards = [
  {
    title: 'First time buyers',
    image: typeFtb,
    alt: 'First time buyers',
    icon: icoFtb,
    iconAlt: 'First time buyers icon',
  },
  {
    title: 'Moving home',
    image: typeMovingHome,
    alt: 'Moving home',
    icon: icoInterestRate,
    iconAlt: 'Moving home icon',
  },
  {
    title: 'Remortgaging',
    image: typeRemortgage,
    alt: 'Remortgaging',
    icon: icoMortgage,
    iconAlt: 'Remortgaging icon',
  },
];

const HereToHelp = () => {
  return (
    <Wrapper background="backgroundDark">
      <Container>
        <Text as="h3" styleType="h2" color="white">
          Strike Financial Services (now Purplebricks Mortgages) is here to help
          everyone{' '}
        </Text>
        <Text ext mt={2} color="white">
          And we mean everyone. Whether you&apos;re buying a Strike/Purplebricks
          property or not, we&apos;re here to find the right mortgage for you.
        </Text>
        <MortgageTypesCardContainer>
          {mortgageTypeCards.map(card => (
            <MortgageTypeCard key={card.title} cardData={card} />
          ))}
        </MortgageTypesCardContainer>
        <ButtonContainer>
          <GoToPBMortgages />
        </ButtonContainer>
      </Container>
    </Wrapper>
  );
};

export default HereToHelp;
