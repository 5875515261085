import styled from 'styled-components';

export const MortgageTypesCardContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-top: ${({ theme }) => theme.spacing.xl};
  row-gap: ${({ theme }) => theme.spacing.md};

  ${({ theme }) => theme.media.smallTablet} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${({ theme }) => theme.spacing.md};
    row-gap: 0;
  }
`;

export const ReviewsContainer = styled.div`
  position: absolute;
  right: 0;
  top: ${({ theme }) => theme.spacing.xl};
  
  ${({ theme }) => theme.media.smallTablet} {
    top: 0;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  position: relative;
  margin-top: ${({ theme }) => theme.spacing.xl};
`;
