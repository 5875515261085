import styled from 'styled-components';
import { Button } from '@strikelabs/luna';

export const ButtonCopyWrapper = styled.div`
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;

export const StyledButton = styled(Button)`
  transition: border-color 0.2s ease-in-out, background-color 0.2s ease-in-out,
    stroke 0.2s ease-in-out;

  &:hover .new-path-icon > path {
    stroke: ${({ theme }) => theme.colors.background};
  }

  @media (max-width: 415px) {
    span:first-child {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  @media (max-width: 380px) {
    .new-path-icon {
      display: none;
    }
  }
`;
