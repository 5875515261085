import React from 'react';
import { Text, Box, Flex } from '@strikelabs/luna';
import LottieAnimation from 'react-lottie';

import { Wrapper, ContainerSmall, StyledUl, StyledLi } from '../style';
import * as motionData from 'images/pages/mortgages/motion_mortgage_machine.json';
import { GoToPBMortgages } from '../../shared/GoToPBMortgages';

const animationOptions = {
  loop: true,
  animationData: motionData,
};

const bullets = [
  {
    id: 0,
    content:
      'A dedicated case manager making your mortgage journey run smoothly, from start to finish.',
  },
  { id: 1, content: 'Open 7 days a week and always here to help.' },
  {
    id: 2,
    content:
      "You'll get access to over 12,000 mortgage deals across 90+ lenders, and exclusive deals.",
  },
  {
    id: 3,
    content:
      'Getting started is easy — no question is too small and no situation is too complex.',
  },
  {
    id: 4,
    content:
      "You'll never have to worry with mortgage protection,  life insurance, and more options for your peace of mind.",
  },
];

const NinetySevenPercent = () => {
  return (
    <Wrapper>
      <ContainerSmall>
        <Flex flexDirection={['column', 'column', 'row']}>
          <Flex
            width={[1, 1, 1 / 2]}
            justifyContent="center"
            alignItems="center"
          >
            <Box width={426} mb={['0px', '0px', 5, 5, 5, 6]}>
              <LottieAnimation options={animationOptions} />
            </Box>
          </Flex>
          <Box width={[1, 1, 1 / 2]}>
            <Text as="h3" styleType="h2">
              There&apos;s a reason why 97% of customers would recommend Strike
              Financial Services (now Purplebricks Mortgages)
            </Text>
            <Text ext mt={3}>
              <StyledUl>
                {bullets.map(bullet => (
                  <StyledLi key={bullet.id}>{bullet.content}</StyledLi>
                ))}
              </StyledUl>
            </Text>
            <Box mt={4}>
              <GoToPBMortgages />
            </Box>
          </Box>
        </Flex>
      </ContainerSmall>
    </Wrapper>
  );
};

export default NinetySevenPercent;
