import React, { useState } from 'react';
import { Text, AspectRatio } from '@strikelabs/luna';

import { Wrapper, ContainerSmall } from '../style';
import {
  GuidesContainer,
  Guide,
  GuideImage,
  GuideTextContainer,
  GuideTitle,
} from './style';

import guideMortgageCalculator from 'images/pages/mortgages/guide_mortgage_calculator.jpg';

const GuidesData = [
  {
    title: 'Is now a good time to buy a house?',
    description:
      "Let's go through the pros and cons of buying right now, so you're armed with all the information to make best decision for you.",
    image: {
      src: 'https://images.ctfassets.net/tpa7oarr87by/37Rm49VanMAZPuarAleoJL/97a1a395055c58edb25baf76111772cf/evelyn-paris-QR_vT8_hBZM-unsplash.jpg',
      alt: 'House with yellow door',
    },
    backgroundColor: 'neon',
    link: '/mortgages/is-now-a-good-time-to-buy-a-house',
  },
  {
    title: 'What is a mortgage broker and do I need one?',
    description:
      'This handy guide should help you to understand their role and find the right person for you.',
    image: {
      src: 'https://images.ctfassets.net/tpa7oarr87by/51JoknWA7TTJjIWkYgJxnm/3969a38ab84198c4c5925d6d1ef1751d/Header_-_mortgage_broker.png',
      alt: 'Couple',
    },
    backgroundColor: 'seaGreen',
    link: '/mortgages/what-is-a-mortgage-broker-and-do-i-need-one',
    textColor: '#fff',
  },
  {
    title: 'How much house can I afford?',
    description:
      "What's your budget? There are a lot of factors to consider, from income to savings to the current interest rates.",
    image: {
      src: 'https://images.ctfassets.net/tpa7oarr87by/3YrD70AOVOGaaPrzjrmSpM/66a7a324b9d2b4a90a21711f3ee72297/zoltan-tasi-oeo9JeFXPwo-unsplash.jpg',
      alt: 'Beach cottage',
    },
    backgroundColor: 'springGreen',
    link: '/mortgages/how-much-house-can-i-afford',
  },
  {
    title: 'What is a Mortgage in Principle?',
    description:
      'We talk you through what a mortgage in principle is — and how you get one — in our handy guide.',
    image: {
      src: 'https://images.ctfassets.net/tpa7oarr87by/1EisJEwRjgNaNgHE3qkbb5/37a3c9aa8642d7345812c7000b23e9c7/Header_-_MIP.png',
      alt: 'Mortgage broker',
    },
    backgroundColor: 'neon',
    link: '/mortgages/what-is-a-mortgage-in-principle',
  },
  {
    title: 'How do mortgage brokers differ from a bank lender?',
    description:
      "Not sure how a mortgage broker works, or whether a mortgage broker or bank lender is best? We'll talk you through all of your options.",
    image: {
      src: 'https://images.ctfassets.net/tpa7oarr87by/3j8Z0JOc9G7gRFVIcJBtQN/47db73477af9877a6f42e8fb9d62ba8e/Header_-_broker_vs_bank.png',
      alt: 'Meeting with mortgage broker',
    },
    backgroundColor: 'seaGreen',
    link: '/mortgages/mortgage-broker-vs-bank-lender',
    textColor: '#fff',
  },
  {
    title: 'Mortgage calculator',
    description:
      'With a few basic details, our mortgage calculator can give you a solid estimation of what you can borrow, and what your monthly repayments might look like.',
    image: {
      src: guideMortgageCalculator,
      alt: 'Mortgage calculator',
    },
    backgroundColor: 'springGreen',
    link: '/mortgages/mortgage-calculator/',
  },
];

const InYourCorner = () => {
  // Initialize hover state as an array with all values set to false
  const [hover, setHover] = useState(Array(GuidesData.length).fill(false));

  return (
    <Wrapper>
      <ContainerSmall>
        <Text as="h3" styleType="h2">
          We&apos;re in your corner
        </Text>
        <Text ext mt={2}>
          We know that selling is personal &mdash; and you need to make the
          right choice for you. Get that you&apos;re-the-boss feeling with our
          buying and selling guides.
        </Text>
        <GuidesContainer>
          {GuidesData.map((guide, idx) => {
            return (
              <Guide
                href={guide.link}
                key={guide.title}
                onMouseEnter={() => {
                  // Set hover state of current guide to true
                  setHover(prevHover => {
                    const newHover = [...prevHover];
                    newHover[idx] = true;
                    return newHover;
                  });
                }}
                onMouseLeave={() => {
                  // Set hover state of current guide to false
                  setHover(prevHover => {
                    const newHover = [...prevHover];
                    newHover[idx] = false;
                    return newHover;
                  });
                }}
              >
                <AspectRatio x={3} y={2}>
                  <GuideImage src={guide.image.src} alt={guide.image.src} />
                </AspectRatio>
                <GuideTextContainer backgroundColor={guide.backgroundColor}>
                  <GuideTitle
                    as="h4"
                    ext
                    color={guide.textColor}
                    hover={hover[idx]}
                  >
                    {guide.title}
                  </GuideTitle>
                  <Text
                    mt={1}
                    ext
                    styleType="body"
                    color={guide.textColor && guide.textColor}
                  >
                    {guide.description}
                  </Text>
                </GuideTextContainer>
              </Guide>
            );
          })}
        </GuidesContainer>
      </ContainerSmall>
    </Wrapper>
  );
};

export default InYourCorner;
