import React from 'react';
import { Text, Flex, Box } from '@strikelabs/luna';

import { Wrapper, ContainerSmall } from '../style';
import illStepOne from 'images/pages/mortgages/ill_step_1.png';
import illStepTwo from 'images/pages/mortgages/ill_step_2.png';
import illStepThree from 'images/pages/mortgages/ill_step_3.png';
import { StepIllustration, StepCard, StyledAnchor } from './style';
import { GoToPBMortgages } from '../../shared/GoToPBMortgages';

const stepsData = [
  {
    title: 'It starts with a chat',
    description: [
      "To start, you'll just need to answer a few questions about your borrowing goals. We find out what you need — and how we can help.",
      "And don't be afraid to ask any question you have along the way — we've seen it all, and we're here to make your mortgage application run smoothly.",
    ],
    image: {
      src: illStepOne,
      alt: 'Step 1',
    },
  },
  {
    title: 'We search thousands of deals',
    description: [
      'Whether you want to borrow more, reduce payments, or find a better rate, your dedicated advisor will do all the legwork — searching dozens of lenders to find you the right deal.',
      "Paperwork? Insurance? Mortgage protection? We'll handle it.",
    ],
    image: {
      src: illStepTwo,
      alt: 'Step 2',
    },
  },
  {
    title: 'You move into your dream home',
    description: [
      'Your advisor will be available 7 days a week and the whole Purplebricks Mortgages team will be with you through the entire mortgage process —  all the way until the keys are in your hand.',
      "That's what we've done for thousands of customers, and we think it's what sets us apart.",
    ],
    image: {
      src: illStepThree,
      alt: 'Step 3',
    },
  },
];

const HowDoesItWork = () => {
  return (
    <Wrapper>
      <ContainerSmall>
        <Text as="h3" styleType="h2">
          So, how does it work?
        </Text>
        {stepsData.map((step, idx) => (
          <StepCard idx={idx} key={step.title}>
            <StepIllustration src={step.image.src} alt={step.image.alt} />
            <Box mt={[4, 4, '0px']} ml={['0px', '0px', 4]}>
              {' '}
              <Text as="h3">
                <strong>{step.title}</strong>
              </Text>
              {step.description.map(p => (
                <Text ext mt={2} key={p.substring(0, 3)}>
                  {p}
                </Text>
              ))}
            </Box>
          </StepCard>
        ))}
        <Flex mt={5} justifyContent="center">
          <GoToPBMortgages />
        </Flex>

        <Text styleType="caption" mt={5}>
          By sending this message you agree for Purplebricks to contact you to
          discuss your estate agency and / or mortgage needs. The{' '}
          <StyledAnchor
            target="__blank"
            rel="noopener noreferrer"
            href="https://www.purplebricks.co.uk/terms/privacy-policy"
          >
            Purplebricks Privacy Policy
          </StyledAnchor>{' '}
          and{' '}
          <StyledAnchor
            target="__blank"
            rel="noopener noreferrer"
            href="https://www.purplebricks.co.uk/financial-services/mortgages-privacy"
          >
            Purplebricks Mortgages Privacy Policy
          </StyledAnchor>{' '}
          detail how we process your data.
        </Text>
        <Text styleType="caption" mt={2}>
          <strong>
            Please note your home may be repossessed if you do not keep up
            repayments on your mortgage.
          </strong>
        </Text>
        <Text styleType="caption" mt={2}>
          © 2024 Purplebricks Mortgages Limited. All rights reserved.
          Purplebricks Mortgages Limited is an appointed representative of
          Mortgage Advice Bureau Limited and Mortgage Advice Bureau (Derby)
          Limited which are authorised and regulated by the Financial Conduct
          Authority.
        </Text>
        <Text styleType="caption" mt={2}>
          Purplebricks Mortgages Limited. Registered Office: 650 The Crescent,
          Colchester Business Park, Colchester, CO4 9YQ. Registered in England
          with company number 11952059.
        </Text>
        <Text styleType="caption" mt={2}>
          Strike Limited and Purplebricks Property Limited are part of the same
          group of companies as Purplebricks Mortgage Limited, and will not
          receive any payment from Purplebricks Mortgages for introducing you to
          them.
        </Text>
        <Text styleType="caption" mt={2}>
          Our aim is to provide you with a high standard of service and for you
          to be happy with the advice you receive. However, and we hope you
          never need to use it, here&apos;s{' '}
          <StyledAnchor
            target="__blank"
            rel="noopener noreferrer"
            href="https://www.purplebricks.co.uk/financial-services/mortgages-complaints"
          >
            Purplebricks Mortgages&apos; complaints procedure
          </StyledAnchor>
          .
        </Text>
      </ContainerSmall>
    </Wrapper>
  );
};

export default HowDoesItWork;
