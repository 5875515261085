import React from 'react';

import useInView from 'hooks/useInView';
import Layout from 'components/Layout';
import SEO from 'components/SEO';
import BrandImg from 'images/strike-brand.png';
import {
  Hero,
  MSEQuote,
  ForEveryone,
  NinetySevenPercent,
  Transparent,
  HereToHelp,
  BigMoves,
  HowDoesItWork,
  InYourCorner,
} from 'components/Pages/Mortgages/Sections';

const ctaConfig = {
  link: '/mortgage-advice',
  text: 'Get mortgage advice',
};

const Mortgages = () => {
  const { ref, inView } = useInView();

  return (
    <Layout showFixedCta={!inView} ctaConfig={ctaConfig}>
      <SEO
        title="Mortgages | Simple, Straightforward Mortgage Advice"
        description="We Offer Simple, Straightforward Mortgage Advice. No Jargon, No Stress. Advice And Access To 12,000 Mortgage Deals From A Large Panel Of Mortgage Lenders."
        canonical="/services/mortgages"
        metaImage={BrandImg}
      />
      <div ref={ref}>
        <Hero />
      </div>
      <MSEQuote />
      <ForEveryone />
      <NinetySevenPercent />
      <Transparent />
      <HereToHelp />
      <BigMoves />
      <HowDoesItWork />
      <InYourCorner />
    </Layout>
  );
};

export default Mortgages;
