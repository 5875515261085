import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

export const MaxWidthWrapper = styled.div`
  position: relative;
  margin: 0 auto;
  width: 100%;
  max-width: ${({ theme }) => theme.maxWidth};
`;

export const BackgroundColorWrapper = styled.div`
  width: 100%;
  background: ${({ theme, background }) =>
    background && theme.colors[background]};
`;

export const Wrapper = ({ background, children }) => (
  <BackgroundColorWrapper background={background}>
    <MaxWidthWrapper>{children}</MaxWidthWrapper>
  </BackgroundColorWrapper>
);

export const Container = styled.div`
  position: relative;
  padding: ${({ theme }) =>
    `${theme.spacing.xl} ${theme.defaults.gutterSpacing}`};

  ${({ theme }) => theme.media.smallTablet} {
    padding: ${({ theme }) => `${theme.spacing.xl} ${theme.spacing.rg}`};
  }

  ${({ theme }) => theme.media.giant} {
    padding: ${({ theme }) => `${theme.spacing.xxl} ${theme.spacing.xl}`};
  }
`;

export const ContainerSmall = styled(Container)`
  ${({ theme }) => theme.media.giant} {
    padding-top: 0;
  }
`;

export const StyledUl = styled.ul`
  padding-inline-start: 20px;
`;

export const StyledLi = styled.li`
  margin-top: ${({ theme }) => theme.spacing.sm};

  ${({ theme }) => theme.media.smallTablet} {
    margin-top: ${({ theme }) => theme.spacing.rg};
  }
`;

export const Illustration = styled.img`
  width: ${({ width }) => `${width}px`};
`;

Wrapper.propTypes = {
  background: PropTypes.string,
  children: PropTypes.node.isRequired,
};

Wrapper.defaultProps = {
  background: null,
};
