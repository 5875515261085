import styled from 'styled-components';
import { Flex } from '@strikelabs/luna';
import { Container } from '../style';

export const CustomContainer = styled(Container)`
  ${({ theme }) => theme.media.smallTablet} {
    padding-right: 0;
  }
`;

export const IllustrationContainer = styled(Container)`
  position: relative;
  display: flex;
  justify-content: flex-end;
  align-items: center;

  @media (max-width: 868px) {
    justify-content: center;
  }

  ${({ theme }) => theme.media.smallTablet} {
    padding-left: 0;
  }
`;

export const Illustration = styled.img`
  max-width: 350px;

  @media (max-width: 370px) {
    max-width: 100%;
  }
`;

export const InlineLogo = styled.img`
  height: 50px;
  width: 235px;
`;

export const ActionsWrapper = styled(Flex)`
  gap: 1rem;

  @media (max-width: 1098px) {
    flex-direction: column;
    align-items: center;
  }
`;

export const ButtonCopyWrapper = styled.div`
  display: inline-flex;
  gap: 1rem;
  align-items: center;
`;
