import React from 'react';
import { Text } from '@strikelabs/luna';
import { Wrapper, ContainerSmall } from '../style';
import { TextWrapper } from './style';

export const Transparent = () => {
  return (
    <Wrapper>
      <ContainerSmall>
        <TextWrapper>
          <Text as="h3" styleType="h2">
            Important information
          </Text>
          <Text mt={3} ext>
            There may be a fee for mortgage advice. The actual amount you pay
            will depend upon your circumstances. The fee is up to 1% but a
            typical fee is &pound;299.
          </Text>
          <Text mt={3} ext>
            Your home may be repossessed if you do not keep up repayments on
            your mortgage.
          </Text>
        </TextWrapper>
      </ContainerSmall>
    </Wrapper>
  );
};

export default Transparent;
