import styled from 'styled-components';

export const StatsIllustration = styled.img`
  width: ${({ size }) => (size ? `${size.width}px` : '150px')};

  ${({ theme }) => theme.media.smallTablet} {
    height: ${({ size }) =>
      size?.height ? `${size?.height.smallTablet}px` : '140px'};
    width: auto;
  }

  ${({ theme }) => theme.media.desktop} {
    height: ${({ size }) =>
      size?.height ? `${size?.height.desktop}px` : '180px'};
  }
`;
