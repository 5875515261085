import React from 'react';
import { Text, Box, Flex } from '@strikelabs/luna';

import { Wrapper, Container, StyledUl, StyledLi, Illustration } from '../style';

import illMSE from 'images/pages/mortgages/ill_mse.png';

const bullets = [
  'We scour the market to compare thousands of deals and rates.',
  "You'll have access to exclusive deals you won't find by going direct.",
  'An expert handling your mortgage means you never have to worry about the little details.',
  "Whatever your financial situation or borrowing goals are, we're here to help with unbiased advice.",
];

const ForEveryone = () => {
  return (
    <Wrapper>
      <Container>
        <Flex flexDirection={['column', 'column', 'row']}>
          <Box width={[1, 1, 1 / 2]} order={[1, 1, 0]}>
            <Text as="h3" styleType="h2">
              Here&apos;s why a mortgage broker can make all the difference:
            </Text>
            <Text ext mt={3}>
              Mortgage brokers aren&apos;t just for high-flyers and investors,
              we&apos;re here for first-time buyers, upgraders, downsizers, and
              everyone in between.
            </Text>
            <Text ext>
              <StyledUl>
                {bullets.map(bullet => (
                  <StyledLi key={`${bullet.substring(0, 3)}`}>
                    {bullet}
                  </StyledLi>
                ))}
              </StyledUl>
            </Text>
          </Box>
          <Flex
            width={[1, 1, 1 / 2]}
            justifyContent="center"
            order={[0, 0, 1]}
            alignItems="center"
          >
            <Box pt={[5, 5, '0px']}>
              <Illustration src={illMSE} width={350} alt="Helping hand" />
            </Box>
          </Flex>
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default ForEveryone;
