import styled from 'styled-components';
import { Text, AspectRatio } from '@strikelabs/luna';

const backgroundColorMap = {
  neon: '#00FF7A',
  springGreen: '#65EBA6',
  seaGreen: '#2D7650',
};

export const GuidesContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  margin-top: ${({ theme }) => theme.spacing.xl};
  row-gap: ${({ theme }) => theme.spacing.md};

  ${({ theme }) => theme.media.smallTablet} {
    grid-template-columns: repeat(3, 1fr);
    grid-gap: ${({ theme }) => theme.spacing.md};
  }
`;

export const Guide = styled.a`
  text-decoration: none;
`;

export const GuideImage = styled.img`
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 4px 4px 0 0;
`;

export const GuideTextContainer = styled.div`
  padding: ${({ theme }) => theme.spacing.rg};
  background: ${({ backgroundColor }) => backgroundColorMap[backgroundColor]};
  border-radius: 0 0 4px 4px;

  ${({ theme }) => theme.media.smallTablet} {
    min-height: 350px;
  }

  ${({ theme }) => theme.media.desktop} {
    min-height: 280px;
  }

  ${({ theme }) => theme.media.giant} {
    min-height: 260px;
  }
`;

export const GuideTitle = styled(Text)`
  font-weight: 700;
  text-decoration: ${({ hover }) => hover && 'underline'};
  color: ${({ textColor }) => textColor && textColor};
`;

export const AspectRatioX = styled(AspectRatio)`
  overflow: hidden; // add this line
`;
