import React from 'react';
import { Text } from '@strikelabs/luna';

import { Wrapper } from '../style';
import { Avatar, StyledContainer, StyledFlex } from './style';
import MSELogo from './MSELogo';
import martinLewis from 'images/pages/mortgages/martin_lewis_mse.jpg';

const MSEQuote = () => (
  <>
    <Wrapper>
      <StyledContainer>
        <StyledFlex
          alignItems="center"
          flexDirection="column"
          textAlign="center"
          backgroundColor="backgroundLight"
          padding={5}
        >
          <MSELogo />
          <Text as="h2" mt={4}>
            &ldquo;Qualified mortgage brokers are also worth their weight in
            gold.&rdquo;
          </Text>
          <Avatar src={martinLewis} alt="Martin Lewis of Money Saving Expert" />
          <Text ext mt={2}>
            <strong>Martin Lewis&apos;</strong> Money Saving Expert
          </Text>
        </StyledFlex>
      </StyledContainer>
    </Wrapper>
  </>
);

export default MSEQuote;
