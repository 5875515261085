import React from 'react';
import { Text, Flex, Box } from '@strikelabs/luna';
import { Wrapper } from '../style';
import {
  ActionsWrapper,
  CustomContainer as Container,
  IllustrationContainer,
  Illustration,
  InlineLogo,
} from './style';
import illHero from 'images/pages/mortgages/ill_moving_hero.png';
import inlineLogo from 'images/pages/mortgages/pb_mortgages_logo.png';
import { GoToPBMortgages } from '../../shared/GoToPBMortgages';

export const Hero = () => {
  return (
    <Wrapper background="backgroundLight">
      <Flex flexDirection={['column', 'column', 'column', 'row']}>
        <Box width={[1, 1, 1, 2 / 5]}>
          <Container>
            <Text as="h1" ext>
              We&apos;ve moved home...
            </Text>
            <Text mt={2} ext>
              We at Strike Financial Services have changed our name to
              Purplebricks Mortgages.
            </Text>
            <Text mt={1} ext>
              If you&apos;re looking to borrow more, find a better rate, or just
              get the latest information, we at Purplebricks Mortgages are here
              to help you.
            </Text>
            <ActionsWrapper
              mt={4}
              flexDirection={['column', 'column', 'column', 'row']}
            >
              <GoToPBMortgages />
              <InlineLogo src={inlineLogo} alt="Purplebricks Mortgages" />
            </ActionsWrapper>
          </Container>
        </Box>
        <Box width={[1, 1, 1, 3 / 5]}>
          <IllustrationContainer>
            <Illustration src={illHero} alt="Mortgages hero" />
          </IllustrationContainer>
        </Box>
      </Flex>
    </Wrapper>
  );
};

export default Hero;
