import React from 'react';
import { Text, Flex } from '@strikelabs/luna';

import illHappyCustomers from 'images/pages/mortgages/ill_happy_customers.png';
import illHomesInsured from 'images/pages/mortgages/ill_homes_insured.png';
import illMortgagesArranged from 'images/pages/mortgages/ill_mortgages_arranged.png';
import { Wrapper, Container } from '../style';
import Stat from './Stat';

const statsData = {
  happyCustomers: {
    image: {
      src: illHappyCustomers,
      alt: 'Happy customers illustration',
      size: {
        width: 150,
        height: {
          smallTablet: 140,
          desktop: 180,
        },
      },
    },
    stat: '13,404',
    description: 'Happy customers',
  },
  homesInsured: {
    image: {
      src: illHomesInsured,
      alt: 'Homes insured illustration',
      size: {
        width: 160,
        height: {
          smallTablet: 140,
          desktop: 170,
        },
      },
    },
    stat: '1,951',
    description: 'Homes insured',
  },
  mortgagesArranged: {
    image: {
      src: illMortgagesArranged,
      alt: 'Mortgages arranged illustration',
      size: {
        width: 130,
        height: {
          smallTablet: 150,
          desktop: 180,
        },
      },
    },
    stat: '£1.65 billion',
    description: 'Of mortgages arranged',
  },
};

const BigMoves = () => {
  return (
    <Wrapper>
      <Container>
        <Text as="h3" styleType="h2">
          They&apos;re making big moves &mdash; and would love for you to join
        </Text>
        <Flex
          justifyContent={['initial', 'initial', 'space-around']}
          flexDirection={['column', 'column', 'row']}
          mt={[4, 4, 5]}
        >
          {Object.keys(statsData).map((key, idx) => (
            <Stat statData={statsData[key]} key={key} idx={idx} />
          ))}
        </Flex>
      </Container>
    </Wrapper>
  );
};

export default BigMoves;
