import React from 'react';
import { Text, Box, Flex } from '@strikelabs/luna';
import PropTypes from 'prop-types';

import { StatsIllustration } from './style';

export const Stat = ({ statData, idx }) => {
  return (
    <Flex
      flexDirection="column"
      textAlign="center"
      alignItems="center"
      mt={[idx !== 0 && 4, idx !== 0 && 4, 0]}
    >
      <StatsIllustration
        size={statData.image.size}
        src={statData.image.src}
        alt={statData.image.alt}
      />
      <Box mt="auto">
        <Text as="h4" styleType="h2" mt={3}>
          {statData.stat}
        </Text>
        <Text styleType="body" ext mt={0}>
          {statData.description}
        </Text>
      </Box>
    </Flex>
  );
};

Stat.propTypes = {
  statData: PropTypes.shape({
    image: PropTypes.shape({
      src: PropTypes.string.isRequired,
      alt: PropTypes.string.isRequired,
      size: PropTypes.shape({
        width: PropTypes.number.isRequired,
        height: PropTypes.shape({
          smallTablet: PropTypes.number,
          desktop: PropTypes.number,
        }),
      }),
    }).isRequired,
    stat: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
  idx: PropTypes.number.isRequired,
};

export default Stat;
