import React from 'react';
import styled from 'styled-components';

const Path = styled.path`
  stroke: black;
  stroke-linecap: round;
  stroke-linejoin: round;
  stroke-width: 2.72399;
`;

export function NewTab() {
  return (
    <svg
      width="21"
      height="22"
      viewBox="0 0 21 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="new-path-icon"
    >
      <Path d="M19.4337 2.32812L9.83984 12.0372" />
      <Path d="M13.8203 2H19.6103V7.75132" />
      <Path d="M9.95528 4.56641H2V19.8804H17.314L17.3138 11.9249" />
    </svg>
  );
}
