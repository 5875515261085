import React from 'react';
import { Box, Text } from '@strikelabs/luna';
import PropTypes from 'prop-types';

import {
  TypeCard,
  SemiCircle,
  AuthorText,
  CardImg,
  RelativeAspectRatio as AspectRatio,
} from './style';

const MortgageTypeCard = ({ cardData }) => {
  return (
    <TypeCard>
      <AspectRatio x={3} y={2}>
        <CardImg src={cardData.image} alt={cardData.alt} />
        <SemiCircle src={cardData.icon} />
      </AspectRatio>
      <Box p={1}>
        <Text as="h2">
          <strong>{cardData.title}</strong>
        </Text>
        {cardData?.quote ? (
          <Text styleType="body" ext mt={1} pb={4}>
            <small>{cardData.quote}</small>
          </Text>
        ) : null}
        {cardData?.author ? (
          <AuthorText styleType="caption" ext>
            &mdash; {cardData.author}
          </AuthorText>
        ) : null}
      </Box>
    </TypeCard>
  );
};

MortgageTypeCard.propTypes = {
  cardData: PropTypes.shape({
    image: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
    icon: PropTypes.string.isRequired,
    iconAlt: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
    quote: PropTypes.string.isRequired,
    author: PropTypes.string.isRequired,
  }).isRequired,
};

export default MortgageTypeCard;
