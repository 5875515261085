import styled from 'styled-components';
import { Flex } from '@strikelabs/luna';

import { Container } from '../style';

export const Avatar = styled.img`
  width: 60px;
  border-radius: 50%;
  margin-top: ${({ theme }) => theme.spacing.xl};
`;

export const StyledContainer = styled(Container)`
  padding: ${({ theme }) => theme.spacing.rg};

  ${({ theme }) => theme.media.smallTablet} {
    padding: ${({ theme }) => theme.spacing.xl};
  }
`;

export const StyledFlex = styled(Flex)`
  border-radius: 5px;
`;
