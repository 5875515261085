import React from 'react';
import { NewTab } from '../../../Icons/NewTab';
import Link from 'components/Link';
import { useIsMedia } from '@strikelabs/luna';
import { ButtonCopyWrapper, StyledButton } from './style';

export function GoToPBMortgages() {
  const { isMobile } = useIsMedia();

  return (
    <Link to="https://purplebricks.co.uk/financial-services/mortgages?utm_source=strike&utm_medium=referral">
      <StyledButton fullWidth={isMobile} styleType="pb-transition">
        <ButtonCopyWrapper>
          Go to Purplebricks Mortgages <NewTab className="icon" />
        </ButtonCopyWrapper>
      </StyledButton>
    </Link>
  );
}
