import styled from 'styled-components';

export const StepIllustration = styled.img`
  width: 150px;
  align-self: center;

  ${({ theme }) => theme.media.smallTablet} {
    width: 185px;
  }
`;

export const StepCard = styled.div`
  display: flex;
  width: 100%;
  background: ${({ theme }) => theme.colors.backgroundLight};
  flex-direction: column;
  align-items: center;
  margin-top: ${({ theme, idx }) =>
    idx === 0 ? theme.spacing.xl : theme.spacing.md};
  padding: ${({ theme }) => `${theme.spacing.md} ${theme.spacing.sm}`};

  ${({ theme }) => theme.media.smallTablet} {
    padding: ${({ theme }) => `${theme.spacing.md} ${theme.spacing.lg}`};
    flex-direction: row;
    align-items: normal;
  }

  ${({ theme }) => theme.media.giant} {
    padding: ${({ theme }) => `${theme.spacing.md} ${theme.spacing.xl}`};
  }
`;

export const StyledAnchor = styled.a`
  font-weight: bold;
`;
